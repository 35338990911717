import React from "react"

import Seo from "src/components/seo"
import Layout from "src/components/Layout"
import Contact from "src/components/Sections/Process/Contact"
import PrivacyPolicyContent from "src/components/Sections/PrivacyPolicy/PrivacyPolicyContent"
import { Themes } from "src/theme/context"

const PrivacyPolicy = () => {
  return (
    <Layout
      footerTheme={Themes.secondary}
      footerStyles={{ backgroundColor: "#6D7092" }}
    >
      <Seo title="Privacy Policy" description="We build digital products." />
      <PrivacyPolicyContent />
      <Contact />
    </Layout>
  )
}

export default PrivacyPolicy
