import React, { useContext, useEffect } from "react"
import gsap from "gsap"

import ThemeContext, { Themes } from "src/theme/context"

import * as styles from "./PrivacyPolicyContent.module.scss"

export default function PrivacyPolicyContent({ location = {} }) {
  const { changeTheme } = useContext(ThemeContext)

  useEffect(() => {
    function toggleDark() {
      changeTheme(Themes.dark)
    }

    function toggleLight() {
      changeTheme(Themes.light)
    }

    toggleDark()

    const theme = gsap.timeline({
      scrollTrigger: {
        trigger: `.${styles.HeaderWrapper}`,
        start: "top top",
        onEnter: toggleDark,
        onLeave: toggleLight,
        onEnterBack: toggleDark,
        onLeaveBack: toggleDark,
      },
    })

    return () => {
      theme.kill()
    }
  }, [])

  return (
    <section className={styles.Wrapper}>
      <div className={styles.HeaderWrapper}>
        <h1>Privacy policy</h1>
      </div>
      <article className={styles.Article}>
        <div>
          <p>
            We use cookies to ensure that we give you the best experience on our
            website. By continuing to use this website, you agree to our Privacy
            Policy.
          </p>
        </div>

        <div>
          <h3>1. Website administrator</h3>
          <p>
            The blesu.com website’s owner and administrator is Blesu Sp. z o. o.
            incorporated under the laws of the Republic of Poland with
            registered office in Prądzyńskiego 34 street, 05-080 Izabelin and
            registered in Polish National Court Register, under KRS number:
            0000701705, Tax Identification Numbe: 1182156865, REGON: 368624511.
          </p>
        </div>

        <div>
          <h3>2. Why we collect information</h3>
          <p>
            We use and collect information about users in order to provide
            services and to better understand how visitors use the website and
            to present important information to them.
          </p>
        </div>

        <div>
          <h3>3. How we use collected information</h3>
          <p>
            We may use the information we collect from you when you register,
            make a purchase, sign up for our newsletter, respond to a survey or
            marketing communication, surf the website, or use certain other site
            features in the following ways:
          </p>
          <ul>
            <li>
              To improve our website in order to offer better services for our
              customers
            </li>
            <li> To personalise user experience</li>
            <li>To provide customer care and support</li>
            <li>To run a survey</li>
            <li>To process payments</li>
            <li>To follow up with the customers (live chat or email).</li>
          </ul>
        </div>

        <div>
          <h3>4. How we collect information</h3>
          <p>
            Like any other website we collect device information using the
            following technologies:
          </p>
          <ul>
            <li>
              Log files - track actions occurring on the webstie, and collect
              data including visitor IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.
            </li>
            <li>
              Web beacons - electronic files used to record information about
              how you browse the Site. Web beacons also go by the name of web
              bugs and are normally used by websites that use third party
              traffic monitoring and tracking services.
            </li>
            <li>
              Cookies - small files that a website transfers to your computer's
              hard drive through your Web browser (if you allow to that) that
              enables the website's to recognize your browser and capture and
              remember your certain personal information.
            </li>
          </ul>
        </div>
        <div>
          <h3>5. Cookies</h3>
          <h4>How do we use cookies?</h4>
          <p>
            We may use cookies to analyse our web traffic using an analytics
            package. Aggregated usage data helps us improve the Website
            structure, design, content and functions. We use tools such as
            Hotjar, Google Analytics, Google Tag Manager, Google Ads. Cookies do
            not provide us with access to your computer or any information about
            you, other than that which you choose to share with us.
          </p>
          <h4>Managing your cookies</h4>
          <p>
            You can find instructions how to mange your cookies in the most
            popular browsers:
          </p>
          <ul>
            <li>
              <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl">
                Google Chrome
              </a>
            </li>
            <li>
              <a href="https://support.mozilla.org/pl/kb/ciasteczka">
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a href="https://help.opera.com/pl/latest/security-and-privacy/">
                Opera
              </a>
            </li>
            <li>
              <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">
                Safari
              </a>
            </li>
            <li>
              <a href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                Microsoft Edge
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3>6. Security of data</h3>
          <p>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site.
          </p>
        </div>
        <div>
          <h3>7. Links to other websites</h3>
          <p>
            Our Website may contain links to other websites. Please note that we
            have no control of websites outside the blesu.com domain. Therefore,
            please read the privacy policies applicable there, as we are not
            responsible for them.
          </p>
        </div>
        <div>
          <h3>8. Changes to this Privacy Policy</h3>
          <p>
            We may revise, modify or amend this Privacy Policy at any time
            without notice. By using this Website you agree to be bound by the
            current version of that Privacy Policy.
          </p>
        </div>
        <div>
          <h3>9. Contact us</h3>
          <p>
            If you would like to make a complaint or you have any questions,
            please contact us by contact form or e-mail at hello@blesu.com
          </p>
        </div>
      </article>
    </section>
  )
}
